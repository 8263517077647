<template>
  <div class="d-flex results-info">
    <span class="results-count">{{ resultsString }} </span>
    <span class="selected-count" v-if="selectedCount > 0">
      ({{ selectedCount }} selected)
    </span>
  </div>
</template>

<script>
export default {
  name: 'SearchFilter',
  props: {
    count: {
      type: Number,
    },
    selectedCount: {
      type: Number,
    },
    resultsDescriptor: {
      type: String,
      default: 'Result',
    },
    resultsDescriptorPlural: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      resultsString: '',
    };
  },
  methods: {
    updateResultsString(count) {
      if (count === undefined) {
        count = 0;
      }

      let { resultsDescriptor } = this;
      if (count !== 1) {
        if (this.resultsDescriptorPlural === '') {
          resultsDescriptor = `${resultsDescriptor}s`;
        } else {
          resultsDescriptor = this.resultsDescriptorPlural;
        }
      }
      this.resultsString = `${count} ${resultsDescriptor} `;
    },
  },
  mounted() {
    this.updateResultsString();
  },
  watch: {
    count(newCount) {
      this.updateResultsString(newCount);
    },
  },
};

</script>

<style lang="scss" scoped>
.results-info {
  padding: 0.625rem 0;
  font-size: 0.875rem;
  font-weight: 600;

  .selected-count {
    font-weight: 400;
    margin-left: 0.5rem;
  }
}
</style>
